import api from '@/store/api';

import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

// @ts-ignore
import { SET_USER_SUCCESS } from '@/store/modules/auth/mutations';

// @ts-ignore
import { NAMESPACE as AUTH_NAMESPACE } from '@/store/modules/auth';

import { NAMESPACE } from './index';

import { UserSettingsMutations } from './mutations';
import products from '@/products';

import merge from 'lodash/merge';

/**
 * Loads the user settings for the given userID in the given product category.
 *
 * @return {IterableIterator<*>}
 */
export function* loadUserSettings(): IterableIterator<any> {
  const product = yield select((state) => state.product);
  const { defaultUserSettings } = products[product as unknown as keyof typeof products].props;

  const userId = yield select((state) => state.auth.user.data.id);

  yield put({ type: NAMESPACE + UserSettingsMutations.SET_USER_SETTINGS });

  try {
    // @ts-ignore
    const { data } = yield call(api, 'get', `${product}/settings/${userId}`);

    yield put({
      type: NAMESPACE + UserSettingsMutations.SET_USER_SETTINGS_SUCCESS,
      payload: merge(defaultUserSettings, data),
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + UserSettingsMutations.SET_USER_SETTINGS_ERROR,
      payload: { error, defaultUserSettings },
    });
  }
}

export function* upsert() {
  // @ts-ignore
  const product = yield select((state) => state.product);
  // @ts-ignore
  const userId = yield select((state) => state.auth.user.data.id);
  // @ts-ignore
  const userSettings = yield select((state) => state.userSettings.data);

  try {
    yield call(api, 'post', `${product}/settings/${userId}`, { settings: userSettings });

    yield put({
      type: NAMESPACE + UserSettingsMutations.STORE_USER_SETTINGS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: NAMESPACE + UserSettingsMutations.SET_USER_SETTINGS_ERROR,
      payload: error,
    });
  }
}

export default function* root() {
  yield takeLatest(AUTH_NAMESPACE + SET_USER_SUCCESS, loadUserSettings);
  yield takeLatest(NAMESPACE + UserSettingsMutations.STORE_USER_SETTINGS, upsert);
}
